import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message"]
  connect() {
    this.element.addEventListener("submit", this.handleSubmit.bind(this));
  }

  preventFormSubmit(event) {
    event.preventDefault()
  }

  handleSubmit(event) {
    let trixText = document.querySelector('trix-editor');
    let closestInput = trixText.parentElement.querySelector("input");

    if (closestInput.value.trim() === "") {
      this.preventFormSubmit(event)
      this.showMessage()
    }
  }

  showMessage() {
    this.messageTarget.classList.remove("hidden")
  }
}