import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  clickFilterEvent() {
    let pagination = document.getElementById('pagination')

    if (pagination) {
      pagination.src = '#'
      pagination.innerHTML = 'Loading...'
    }
  }
}
