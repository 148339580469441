import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['bookingform', 'bookingprice']
  connect () {
  }

  selectPackage (event) {
    this.url = event.currentTarget.dataset.link
    fetch(this.url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
  }

  selectAddon () {
    const self = this
    const flag = window.location.pathname.includes('edit') ? 'edit' : 'new'
    const params = new URLSearchParams(Array.from(new FormData(self.bookingformTarget))).toString()

    const url = `/bookings/prebuild?${params}&price_field_flag=${flag}`
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))

  }

  changeBookingPrice (event) {
    this.bookingpriceTarget.value = event.currentTarget.value
  }
}
