import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.anchorClicked = false;
  }

  Disable(event) {
    if (!this.anchorClicked) {
      this.anchorClicked = true;
      let element = this.element;
      Array.from(element.parentElement.querySelectorAll('a')).forEach(sibling => {
        sibling.innerText = 'Loading...';
        sibling.classList.remove('bg-primary');
        sibling.classList.remove('hover:bg-primary-focus');
        sibling.classList.add('bg-secondary');
        if (sibling != element) {
          sibling.href = '#';
        }
      });
    } else {
      event.preventDefault();
    }
  }
}
