import { Controller } from "@hotwired/stimulus"

export default class NestedForm extends Controller {
  static targets = ["add_item", "template", "add_preview"]
  static values = { required: Boolean, default: false }

  connect() {
    console.log("NestedForm controller connected...");
  }

  add_association() {
    var new_timestamp = new Date().valueOf()

    // Clone the template content
    var templateContent = this.templateTarget.content.cloneNode(true)
    // Remove the required attribute from the input element
    var inputElement = templateContent.querySelector('input[required]')
    if (inputElement) {
      inputElement.removeAttribute('required')
    }

    // Replace the placeholder in the cloned content
    var content = templateContent.firstElementChild.outerHTML.replace(/TEMPLATE_RECORD/g, new_timestamp)

    console.log("content: ", content)
    var input = this.add_itemTarget
    var timestamp = this.add_itemTarget.id
    if (input.files && input.files[0]) {
      var view = this.add_previewTarget.innerHTML.replace(/TEMPLATE_PREVIEW/g, 'preview_' + timestamp)
        .replace(/TEMPLATE_DELETE/g, 'delete_' + timestamp)
        .replace(/TEMPLATE_SRC/g, 'src_' + timestamp)
      this.add_previewTarget.parentNode.insertAdjacentHTML('beforeend', view)
      this.add_itemTarget.style.display = 'none'
      this.add_itemTarget.required = false
      this.add_itemTarget.innerHTML.replace(/TEMPLATE_ID/g, timestamp)

      this.add_itemTarget.closest(".nested-fields").insertAdjacentHTML('beforebegin', content)
      var image = document.getElementById('src_' + timestamp)

      image.classList.add('hidden')

      var reader = new FileReader();
      reader.onload = function () {
        if (reader.result) {
          image.src = reader.result;
          image.classList.remove('hidden')
        } else {
          console.error('FileReader result is null');
        }
      };
      reader.readAsDataURL(input.files[0])
      document.getElementById('no-images').classList.add("hidden")
    }
  }

  remove_association(event) {
    let item = event.target.closest(".nested-image");
    var items = this.element.querySelectorAll(".nested-image");
    items = Array.from(items);
    let visibleItems = items.filter(item => item.style.display !== "none");

    console.log("visibleItems: ", visibleItems.length)

    if (visibleItems.length === 1 && this.requiredValue) {
      this.showAlert()
      return;
    }

    if (item && item.querySelector("input[name*='_destroy']")) {
      item.querySelector("input[name*='_destroy']").value = 1;
      item.style.display = 'none';
    } else {
      document.getElementById(item.id.split("_")[1]).closest(".nested-fields").remove();
      item.remove();
    }

    for (let i = 0; i < visibleItems.length; i++) {
      visibleItems[i].querySelector("input[name*='position']").value = i + 1;
    }
  }

  showAlert() {
    const alertDiv = document.createElement("div")
    alertDiv.setAttribute('data-controller', 'alert')
    alertDiv.setAttribute('class', 'flex py-4 px-3 m-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow fixed bottom-[65px] md:bottom-0 right-0 z-[100] cursor-pointer alert')
    alertDiv.setAttribute('role', 'alert')
    alertDiv.setAttribute('data-action', 'click->alert#remove')

    let alertInnerTemplate = `
            <div class="inline-flex flex-shrink-0 justify-start items-center w-8 h-6  rounded-full alert">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Check icon</span>
            </div>

            <div class="text-sm font-normal">Gallery Images connot be empty. At least one image must be present</div>

            <button type="button" class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8" data-dismiss-target="#toast-alert" aria-label="Close" id="alert_close">
                <span class="sr-only">Close</span>
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>`
    alertDiv.innerHTML = alertInnerTemplate
    document.body.appendChild(alertDiv)
  }
}
