import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mapContainer"]
  connect() {
  }

  showMap() {
    this.mapContainerTarget.style.zIndex = 38;
  }

  hideMap() {
    this.mapContainerTarget.style.zIndex = 9;
  }
}