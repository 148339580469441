import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["wrapper", "innerBlock", "button", "text", "iconMore", "iconLess"]
  static values = { isClose: { type: Boolean, default: true }, defaultHeight: { type: Number, default: 0 }, hasMargin: { type: Boolean, default: true } }
  connect() {
    this.heightCheck()
  }
  heightCheck() {
    if (this.innerBlockTarget.offsetHeight > this.defaultHeightValue) {
      this.wrapperTarget.style.height = this.defaultHeightValue + "px"
    }
    else {
      this.wrapperTarget.removeAttribute("style");
      this.buttonTarget.style.display = "none"
    }
  }
  switchState() {
    if (this.isCloseValue) {
      !this.hasMarginValue ? this.wrapperTarget.style.height = this.innerBlockTarget.offsetHeight + "px" : this.wrapperTarget.style.height = this.innerBlockTarget.offsetHeight + 32 + "px"
      this.textTarget.innerHTML = "Show less"
      this.iconMoreTarget.classList.add("hidden")
      this.iconLessTarget.classList.remove("hidden")
      this.isCloseValue = !this.isCloseValue
    }
    else {
      this.wrapperTarget.style.height = this.defaultHeightValue + "px"
      this.textTarget.innerHTML = "Show more"
      this.iconMoreTarget.classList.remove("hidden")
      this.iconLessTarget.classList.add("hidden")
      this.isCloseValue = !this.isCloseValue
    }
  }
}