import { Controller } from "@hotwired/stimulus"
import { Modal } from 'flowbite';

// Connects to data-controller="modal"
export default class extends Controller {

  static values = {opened: Boolean}
  connect() {
    this.modal = new Modal(this.element);

    if (this.openedValue) {
      this.modal.show()
    }else{
      this.modal.hide()
    }
  }

  hide() {
    this.modal.hide()
  }

  disconnect() {
    this.modal.hide()
  }

  submitEnd() {
    this.modal.hide()
  }
}
