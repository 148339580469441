import { Controller } from "@hotwired/stimulus"
import * as L from 'leaflet';

export default class extends Controller {
  static targets = ["map"]
  static values = { url: { type: String, default: "" } }
  connect() {
    this.initializeMap()
    this.fetchMarkers()

  }
  initializeMap() {
    this.map()
  }


  map() {
    if (this._map == undefined) {

        this._map = L.map(this.mapTarget, {
            center: [31.87499237836392, -100.19980144307317],
            zoom: 6,
          });
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this._map);

    }
    return this._map
  }

  fetchMarkers(){

    const _this = this

    let fetch_url = this.urlValue
    this.markers = []

    fetch(fetch_url)
        .then((response) => response.json())
        .then((data) =>{
            data.forEach(function (outfitter){
                let position = L.latLng(outfitter.latitude, outfitter.longitude);
                let customMarker = L.divIcon({
                  className: "leaflet-data-marker",
                  html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#434f40" class="w-8 h-8">
                  <path fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" /></svg>`,
                  iconAnchor: [12, 32],
                })

                let marker = L.marker(position, {
                    title: outfitter.title, icon: customMarker
                }).addTo(_this.map());
                let popupContent = `
                <div class="rounded-md shadow-md w-[280px]">
                <a href="${outfitter['url']}" class="!text-base-content">
                  <div class="rounded-t-md overflow-hidden relative w-full pb-[66%] ">
                    <img src="`+ outfitter["image_jpeg"] + `" alt="" class="w-full h-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] object-cover z-[1]">
                  </div>
                  <div class="p-4 rounded-b-md bg-100">
                  <div class="mb-4">
                  <div class="mb-2 flex justify-between flex-nowrap items-center">
                    <div class="max-w-[calc(100%-45px)] overflow-hidden">
                        <h4 class="text-base font-bold text-left truncate whitespace-nowrap capitalize">`+ outfitter['title'] + `</h4>
                      </div>
                      ${(outfitter['rate'] == null) ? ` ` : `<div class="flex justify-end space-x-1 items-center">
                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="text-primary">
                            <path d="M7.04897 0.927078C7.34897 0.00607812 8.65197 0.00607812 8.95097 0.927078L10.021 4.21908C10.0863 4.41957 10.2134 4.59426 10.384 4.71818C10.5547 4.84211 10.7601 4.90892 10.971 4.90908H14.433C15.402 4.90908 15.804 6.14908 15.021 6.71908L12.221 8.75308C12.05 8.87711 11.9227 9.05209 11.8573 9.25293C11.7919 9.45377 11.7918 9.67017 11.857 9.87108L12.927 13.1631C13.227 14.0841 12.172 14.8511 11.387 14.2811L8.58697 12.2471C8.41618 12.1231 8.21053 12.0563 7.99947 12.0563C7.78842 12.0563 7.58277 12.1231 7.41197 12.2471L4.61197 14.2811C3.82797 14.8511 2.77397 14.0841 3.07297 13.1631L4.14297 9.87108C4.20815 9.67017 4.20803 9.45377 4.14264 9.25293C4.07725 9.05209 3.94994 8.87711 3.77897 8.75308L0.979974 6.72008C0.196974 6.15008 0.599974 4.91008 1.56797 4.91008H5.02897C5.24002 4.91013 5.44568 4.84342 5.6165 4.71948C5.78732 4.59554 5.91455 4.42073 5.97997 4.22008L7.04997 0.928078L7.04897 0.927078Z" fill="currentColor"></path>
                          </svg>
                          <p class="text-base text-base-content">` + outfitter['rate'] + `</p>`}
                      </div>
                      </div>
                      <div class="flex flex-col mb-3">
                        <div class="flex items-center justify-start text-sm space-x-2 mb-1">
                          <div class="w-5 h-5 flex items-center justify-center shrink-0">
                          <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="text-base-content/70">
                            <path d="M5.01133 0H4.9886C2.23784 0 0 2.23709 0 4.9868C0 6.78923 0.820857 9.168 2.43976 12.0571C3.64001 14.1991 4.85708 15.8396 4.86927 15.8559C4.90315 15.9014 4.95655 15.9282 5.01312 15.9282C5.01473 15.9282 5.01638 15.9282 5.01799 15.9281C5.07636 15.9265 5.13024 15.8966 5.16262 15.848C5.17463 15.8298 6.38303 14.005 7.57512 11.773C9.18415 8.76049 10 6.47722 10 4.9868C9.99989 2.23705 7.76201 0 5.01133 0ZM7.31401 5.10866C7.31401 6.38467 6.27593 7.42271 4.99993 7.42271C3.72396 7.42271 2.68588 6.38463 2.68588 5.10866C2.68588 3.83269 3.72396 2.79462 4.99993 2.79462C6.27593 2.79462 7.31401 3.83269 7.31401 5.10866Z" fill="currentColor"></path>
                          </svg>
                          </div>
                          <div class="max-w-[calc(100%-20px)] overflow-hidden">
                            <div class="text-sm text-base-content/60 truncate whitespace-nowrap">`+ outfitter['address'] + `</div>
                          </div>
                        </div>
                        <div class="flex items-center justify-start text-sm space-x-2">
                          <div class="w-5 h-5 flex items-center justify-center shrink-0">
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" class="text-base-content/70">
                              <path d="M6.85547 1.00423C3.30525 1.5529 0.518856 4.38771 0.0454926 7.92718C-0.0513318 8.66412 0.0132178 10.0412 0.174592 10.7405C0.997599 14.2154 3.86468 16.6952 7.38263 16.9749C11.0458 17.2654 14.4669 15.0115 15.6181 11.5366C15.9462 10.5576 16 10.1972 16 8.96535C16 8.00786 15.9839 7.78732 15.8763 7.29782C15.1878 4.22095 12.8532 1.84876 9.79786 1.13333C9.31374 1.01499 9.06092 0.993475 8.20026 0.977337C7.64083 0.966579 7.03299 0.977337 6.85547 1.00423ZM9.62035 1.682C11.1642 2.02089 12.6865 2.93534 13.6547 4.09186C14.7144 5.36133 15.2631 6.63081 15.4567 8.27682L15.489 8.53502H13.3642H11.2448L11.2287 8.68025L11.2126 8.83087H9.65262H8.09268L8.07654 7.26016L8.06578 5.68946L8.21102 5.67332L8.36163 5.65718L8.37777 3.58622L8.38853 1.50987L8.80272 1.54753C9.02864 1.56904 9.39443 1.62821 9.62035 1.682ZM7.52787 3.61311V5.68408H7.66234H7.79682V7.27092V8.85777L6.22612 8.84701L4.65003 8.83087L4.63389 8.68025L4.61775 8.53502H2.58444H0.55651L0.599543 8.14234C0.895396 5.32368 2.84264 2.88155 5.55372 1.92406C6.11853 1.72504 6.91465 1.5529 7.27505 1.54753L7.52787 1.54215V3.61311ZM7.79682 10.6867V12.2466H7.66234H7.52787V14.3068C7.52787 15.4418 7.51711 16.3832 7.50097 16.3939C7.45256 16.4477 6.62417 16.3294 6.13467 16.2003C5.26325 15.969 4.33266 15.5225 3.59034 14.99C3.40207 14.8555 3.02015 14.5166 2.74044 14.2369C1.55165 13.0481 0.787813 11.4774 0.599543 9.83677C0.572648 9.6216 0.567268 9.43871 0.578027 9.42257C0.594164 9.40644 1.50862 9.39568 2.61672 9.39568H4.62313V9.2612V9.12672H6.20998H7.79682V10.6867ZM11.2395 9.2612V9.39568H13.3642H15.489L15.4567 9.6485C15.2846 11.1224 14.8058 12.3434 13.9721 13.4515C12.7187 15.1245 10.8199 16.1949 8.72741 16.4101L8.38853 16.4477L8.37777 14.3606L8.36163 12.2735L8.2164 12.2574L8.06578 12.2412V10.6813V9.12672H9.65262H11.2395V9.2612Z" fill="currentColor"></path>
                            </svg>
                          </div>
                          <div class="flex justify-between items-center text-sm text-base-content/60 max-w-[calc(100%-20px)]">
                          <div class="overflow-hidden max-w-[calc(100%-80px)]">
                            <div class="text-sm text-base-content/60 truncate whitespace-nowrap">`+ outfitter['games'] + `</div>
                          </div>
                            <div class="w-[80px]"></div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
                `
                marker.bindPopup(popupContent)
                _this.markers.push(marker)
            })
        })
  }




  refreshMap() {
    this.map().invalidateSize();
  }


}
