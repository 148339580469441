import Carousel from 'stimulus-carousel'

export default class extends Carousel {
  static targets = ["btnNext", "btnPrev", "pagination"]
  connect() {
    super.connect()
    

    // The swiper instance.
    this.swiper

    // Default options for every carousels.
    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions() {
    return {
      slidesPerView: 1,
      pagination: {
        el: this.paginationTarget,
        dynamicBullets: true,
      },      
      navigation: {
        nextEl: this.btnNextTarget,
        prevEl: this.btnPrevTarget,
      },
    }
  }
}