import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hint", "message", "startDateInput", "endDateInput"]

  connect() {
    this.element.addEventListener("submit", this.handleSubmit.bind(this));
  }

  preventFormSubmit(event) {
    event.preventDefault(); // Prevent form submission
  }  

  handleSubmit(event) {    
    this.validateDates();
    if (!this.validateDates()) {
      this.preventFormSubmit(event);
      this.hideHint();
      this.showMessage();      
    }
  }

  validateDates() {
    const startDate = this.startDateInputTarget.value;
    const endDate = this.endDateInputTarget.value;
  
    // Get the current date without time
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    // Check that both fields contain values
    if (startDate && endDate) {
      // Convert strings to Date objects
      const start = new Date(startDate);
      const end = new Date(endDate);
  
      // Check that start date is less than end date and end date is not less than today
      return start < end && end >= today;
    }
  
    // If either field is empty, return false
    return false;
  }

  showMessage() {
    this.messageTarget.classList.remove("hidden"); // Show message
  }

  hideHint() {
    this.hintTarget.classList.add("hidden");
  }
}