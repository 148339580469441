window.videoAutoplay = function(){
  const video = document.querySelector('video.bgvid');
  // we do not want video to be loaded when it is not to be displayed with the current resolution
  if (video && video.checkVisibility()){
    // pause and set time instead of loading to save bandwith on page reload
    video.pause()
    video.currentTime = 0

    video.play()
  }
}

// check for video on page load
document.addEventListener("turbo:load", () => {
  window.oldWidth = window.innerWidth;
  window.videoAutoplay();
});

// check if we need to load the video on page resize
window.addEventListener("resize", (event) => {
  console.log("resize event");
  if ((window.oldWidth < 1024) && (window.innerWidth >= 1024)) {
    window.videoAutoplay();
  }
  window.oldWidth = window.innerWidth;
});

