import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.updateHeader(this.getSelectedGameName());
  }

  changeHeader(e) {
    if (e.detail.isInternalLink) {
      this.updateHeader(e.detail.name);
    }    
  }

  getSelectedGameName() {
    const input = document.getElementById("selectedGameName");
    return input ? input.value : "";
  }

  updateHeader(gameName) {
    if (gameName) {
      this.element.innerHTML = `for ${this.createSpan(gameName)}`;
    }
  }

  createSpan(text) {
    return `<span class="capitalize">"${text}"</span>`;
  }
}
