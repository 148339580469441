import { Controller } from "@hotwired/stimulus"
import { Tabs } from 'flowbite';

export default class extends Controller {
  connect() {
    const tabElements = [{
      id: 'native',
      triggerEl: document.querySelector('#native-game-tab'),
      targetEl: document.querySelector('#native-game')
    },
    {
      id: 'exotic',
      triggerEl: document.querySelector('#exotic-game-tab'),
      targetEl: document.querySelector('#exotic-game')
    }]
    const options = {
      defaultTabId: document.querySelector('#game-tabs')?.getAttribute('active-tab') || 'native',
      activeClasses: 'text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500',
      inactiveClasses: 'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
      onShow: () => {
          // console.log('tab is shown');
      }
    }
    const tabs = new Tabs(tabElements, options);
  }
}
