import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message", "input", "btnSubmit"]

  connect() {
    this.element.addEventListener("keydown", this.handleKeyDown.bind(this));
    this.isSubmitting = false;
  }

  validateContent(event) {
    if (this.isSubmitting) {
      return;
    }

    event.preventDefault();

    let isValid = true;

    this.inputTargets.forEach(input => {
      // Strip HTML tags and trim the resulting string
      const strippedValue = input.value.replace(/<\/?[^>]+(>|$)/g, "").trim();

      if (strippedValue === "") {
          isValid = false;
      }
    });

    if (!isValid) {
      this.showMessage();
    } else {
      this.isSubmitting = true;
      this.btnSubmitTarget.click();
    }
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      if (event.target.closest('trix-editor')) {
        // Allow default action (newline) inside trix-editor
      } else {
        event.preventDefault();
      }
    }
  }

  showMessage() {
    this.messageTarget.classList.remove("hidden")
  }
}