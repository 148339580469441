import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['list', 'upload', 'filename']

  connect() {
    this.scrollToDown()
  }

  scrollToDown() {
    this.listTarget.scrollTop = this.listTarget.scrollHeight
  }

  triggerUpload() {
    this.uploadTarget.click();
  }

  onFileUpload(event) {
    if (event.target.files[0]) {
      const fileName = event.target.files[0].name;
      this.filenameTarget.innerHTML = fileName;
    }
  }
}
