import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["select", "inputId", "inputName", "clearBtn"]
  static values = { url: String, pagePath: String }

  connect() {
    this.initTomSelect()
    this.setDefaultOption()
    this.setFormSubmitListener()

  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
    this.element.removeEventListener("submit", this.handleSubmit.bind(this));
  }

  initTomSelect() {
    this.select = new TomSelect(this.selectTarget, {
      persist: false,
      valueField: 'value',
      labelField: 'name',
      searchField: ['name'],
      optgroupField: 'class',
      load: (query, callback) => {
        if (!query.length) return callback();
        console

        fetch(this.urlValue + '?query=' + query.toString())
          .then(response => response.json())
          .then(json => {
            // add optgroups from json
            json.optgroups.forEach(optgroup => {
              this.select.addOptionGroup(optgroup.value, { label: optgroup.label });
            });

            // pass options to callback
            callback(json.options);
          })
          .catch(() => {
            callback();
          });
      },
      render: {
        optgroup_header: function (data, escape) {
          return `<div class="optgroup-header">${escape(data.label)}</div>`;
        },
        option: function (data) {
          return `
                  <div class="">
                  ${data.showUrl ? `<a href="${data.url}" class="block option-link" data-external-link="${data.externalLink}">${data.name}</a>` : `${data.name}`}
                  </div>
                `
        },
      }
    });

    if (this.select) {
      const _this = this

      _this.select.on("change", function (value, data) {
        _this.updateFields()
        //go to search result page
        if (this.activeOption.firstChild.nextElementSibling) {
          let searchResultUrl = this.activeOption.firstChild.nextElementSibling.href
          window.location.href = searchResultUrl
        }

        //reset search result if user delete option from select with backspace
        if (this.control.children[0] && this.control.children[0].tagName === 'INPUT') {
          _this.resetSearch()
        }
      })
    }

  }


  setDefaultOption() {
    const gameId = this.inputIdTarget.value;
    const gameName = this.inputNameTarget.value;

    if (gameId && gameName) {
      // Check if the option already exists
      let optionExists = this.select.getOption(gameId);

      if (!optionExists) {
        // Add the new option if it doesn't exist
        this.select.addOption({ value: gameId, name: gameName });
      }

      // Set the value to make the option selected
      this.select.setValue(gameId);
    }
  }

  updateFields() {
    this.inputIdTarget.value = this.select.activeOption.dataset.value
    this.inputNameTarget.value = this.select.activeOption.innerText.trim(" ")

    if (this.select.activeOption.firstChild.nextElementSibling) {
      this.internallLink = this.select.activeOption.firstChild.nextElementSibling.dataset.internallLink
    }
    
    const event = new CustomEvent("fieldChanged",
      {
        detail:
        {
          name: this.inputNameTarget.value,
          internallLink: this.internallLink
        }
      });

    window.dispatchEvent(event)
  }

  //clear select button
  clearSelect() {
    this.select.clear()
    this.resetSearch()
  }

  // go to list with all hunts
  resetSearch() {
    window.location.href = this.pagePathValue
  }

  setFormSubmitListener() {
    this.element.addEventListener("submit", this.handleSubmit.bind(this));
  }

  preventFormSubmit(event) {
    event.preventDefault()
  }

  handleSubmit(event) {
    this.preventFormSubmit(event)
    this.resetSearch()
  }
}
