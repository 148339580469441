import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["conversation"]

  connect() {
    const conversation = document.querySelector('.message-wrapper')
    if (conversation && window.location.pathname === '/conversations') {
      conversation.click();
    }    
  }

  showMessages() {
    if (window.innerWidth < 640) {
      this.element.classList.add("-translate-x-[100vw]")
    }
    else {
      return false
    }
  }

  searchConversations() {
    if (event.currentTarget.value) {
      const url = `/conversations?q=${event.currentTarget.value}`
      fetch(url, {
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      })
          .then(r => r.text())
          .then(html => Turbo.renderStreamMessage(html))
    }
  }

  toggle() {
    const classList = ['border-l-2', 'border-primary', 'bg-primary/10'];
    Array.from(document.getElementsByClassName('message-wrapper border-l-2 border-primary bg-primary/10')).forEach((item) => {
      item.classList.remove(...classList);
    })
    event.currentTarget.classList.add(...classList);
  }
  hideMessages() {
    if (window.innerWidth < 640) {
      this.element.classList.remove("-translate-x-[100vw]")
    }
    else {
      return false
    }
  }
  clearStyles() {
    if (window.innerWidth > 640 && this.element.classList.contains("-translate-x-[100vw]")) {
      this.element.classList.remove("-translate-x-[100vw]")
    }
  }

}
