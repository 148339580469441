import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["datepicker", "selectedDate", "selectedWeekRow", "selectedWeeksTitle", "row", "deleteBtn"]
  connect() {
    this.initDatepicker()
    this.getSelectedDate()
    this.clearDate()
  }

  initDatepicker() {
    let dates = [];
    if (this.datepickerTarget.dataset.disableddates) {
      dates = this.datepickerTarget.dataset.disableddates.split('&')
    }

    // do not allow to order promotions for more than a year in advance
    let nextYear = new Date();
    const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    nextYear.setTime(nextYear.getTime() + (53 * weekInMilliseconds));

    flatpickr(this.datepickerTarget, {
      disable: [
        function (date) {
          return date.getDay() >= 1 && date.getDay() <= 6;
        }
      ],
      minDate: "today",
      maxDate: nextYear,
      disableWeekends: true
    });
  }

  initSelect() {
    const options = {
      sortField: {
        field: "text",
        direction: "asc"
      }
    }
    this.select = new TomSelect(this.selectTarget, options)
    this.select.on('item_add', function (value, data) {
      const url = `/promotions/games?game_id=${value}`
      fetch(url, {
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
    })
  }

  getPrices() {
    const dates = this.selectedDateTarget.value
    const gameId = this.selectedDateTarget.dataset.gameid
    const url = `/promotions/prices?dates=${dates}&game_id=${gameId}`
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  getSelectedDate() {
    const _this = this
    _this.selectedDateArr = []
    _this.counter = 0
    _this.datepickerTarget.addEventListener('change', (e) => {
      const disabledDate = "Invalid Date"
      const startDate = new Date(e.target.value)
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 6);
      const options = { day: "2-digit", month: "short", year: "numeric" };
      const dateForArray = startDate.toLocaleDateString("en-US");
      const startDateFormattedView = startDate.toLocaleDateString("en-US", options);
      const endDateFormattedView = endDate.toLocaleDateString("en-US", options);
      if (_this.selectedDateArr.includes(dateForArray)) {
        return false
      }
      else {
        if (dateForArray !== disabledDate) {
          _this.selectedDateArr.push(dateForArray)
        }
      }
      _this.selectedDateTarget.value = _this.selectedDateArr
      if (startDateFormattedView !== disabledDate) {
        _this.selectedWeekRowTarget.innerHTML += `
        <div data-promoted-dates-target="row" id="${_this.counter}" class=" w-full flex justify-between md:justify-end items-center gap-x-3">
          <div class="shrink-0 text-left">${startDateFormattedView} - ${endDateFormattedView}</div>
            <button type="button" data-action="click->promoted-dates#removeWeek" data-promoted-dates-target="deleteBtn" class="text-red-600 w-6 h-6 shrink-0 flex justify-end items-center bg-remove-icon bg-no-repeat bg-right"></button>
          </div>
        </div> 
      `
        _this.counter++
      }
      _this.getPrices()
      if (_this.rowTargets.length > 0) {
        _this.selectedWeeksTitleTarget.classList.remove("hidden")
        _this.selectedWeeksTitleTarget.classList.add("block")
      }
      _this.clearDate()
    })

  }

  clearDate() {
    this.datepickerTarget.value = null
  }

  removeWeek(event) {
    const _this = this
    _this.selectedDateArr.splice(event.target.parentNode.id, 1)
    _this.selectedDateTarget.value = _this.selectedDateArr
    event.target.parentNode.remove()
    _this.getPrices()
    let rows = _this.rowTargets
    for (let i = 0; i < rows.length; i++) {
      rows[i].id = i
    }
    if (rows.length == 0) {
      _this.counter = 0
      _this.selectedWeeksTitleTarget.classList.remove("block")
      _this.selectedWeeksTitleTarget.classList.add("hidden")
    }
  }

  deleteAllSelectedDates() {
    this.deleteBtnTargets.forEach(button => {
      button.click()
    })
  }
}
