import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["btnSubmit"]
  connect() {
    console.log("autosave connected")
  }

  toggleStatus() {
    this.btnSubmitTarget.click()
  }
}