import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["text", "button", "iconMore"]
  static values = { defaultHeight: { type: Number, default: 72 } }

  connect() {
    console.log("ShowMoreModalController connected");
    this.heightCheck()
  }

  heightCheck() {
    if (this.textTarget.scrollHeight > this.defaultHeightValue) {
      this.textTarget.style.height = this.defaultHeightValue + "px"
      this.textTarget.style.overflow = "hidden"
      this.buttonTarget.style.display = "flex"
    } else {
      this.textTarget.removeAttribute("style");
      this.buttonTarget.style.display = "none"
    }
  }

  toggleHeight(event) {
    event.preventDefault(); // Prevent default click action

    if (this.textTarget.style.height === this.defaultHeightValue + "px") {
      this.textTarget.style.height = this.textTarget.scrollHeight + "px"
      this.buttonTarget.querySelector("span").textContent = "Show Less"
      this.iconMoreTarget.style.transform = "rotate(180deg)"
    } else {
      this.textTarget.style.height = this.defaultHeightValue + "px"
      this.buttonTarget.querySelector("span").textContent = "Show More"
      this.iconMoreTarget.style.transform = "rotate(0deg)"
    }
  }
}