import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["hunter", "outfitter", "role"]

  connect(){
  }

  hunter_role() {
    this.hunterTarget.ariaSelected = true
    this.outfitterTarget.ariaSelected = false
    this.roleTarget.value = 'customer'
  }

  outfitter_role() {
    this.outfitterTarget.ariaSelected = true
    this.hunterTarget.ariaSelected = false
    this.roleTarget.value = 'outfitter'
  }
}
