import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect() {
  }

  selectPackageGame() {
    // TODO: check if it is still needed
    if (event.currentTarget.value && event.currentTarget.value.length) {
      const identifier = event.currentTarget.dataset.identifier;
      const url = `/outfitters/package_game_settings/${event.currentTarget.value}?package_game_identifier=${identifier}`;

      fetch(url, {
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      }).then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }
}
