import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["primaryBlock", "secondaryBlock"]
  connect() {

  }
  show() {
    this.primaryBlockTarget.classList.remove("hidden")
    this.element.classList.add("!z-50")
    this.resetMapBounding()
  }

  hide() {
    this.primaryBlockTarget.classList.add("hidden")
    this.element.classList.remove("!z-50")
  }

  resetMapBounding(){
    const event = new CustomEvent("changeMapSize")
    window.dispatchEvent(event)
  }

 /*Disabling Pull-to-Refresh Feature on Mobile*/
 addOIverscrollContain(){
  document.querySelector("body").classList.add("overscroll-contain")
}

removeOIverscrollContain(){    
  document.querySelector("body").classList.remove("overscroll-contain")
}


}
