import { Controller } from "@hotwired/stimulus";
import * as L from "leaflet"

export default class extends Controller {
  static targets = ["field", "map", "latitude", "longitude"];
  static values = {zoom: {type: Number, default: 6}, autocomplete:{type: Boolean, default: false}, draggable:{type: Boolean, default: false},}

  connect() {
    if (this._map == undefined) {
      this.initializeMap()
    }
  }

  initializeMap() {
    this.map()
    this.marker()
    if(this.autocompleteValue){
      this.autocomplete()
    }
  }

  map() {
    if (this._map == undefined) {
      this._map = L.map(this.mapTarget, {
        center: [this.latitudeTarget.value, this.longitudeTarget.value],
        zoom: this.zoomValue,
      });
      L.tileLayer("https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=9d0b0eff52fb4f0791c65e24eb09cfbb").addTo(this._map);
    }
    this._map.attributionControl.setPrefix(false)
    return this._map
  }

  marker() {
    const _this = this
    if (_this._marker == undefined) {
      let mapLocation = [this.latitudeTarget.value, this.longitudeTarget.value]
      let customMarker = L.divIcon({
        className: "leaflet-data-marker",
        html:  `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#434f40" class="w-8 h-8">
        <path fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" /></svg>`,
        iconAnchor: [13, 32],

      })
      _this._marker = L.marker(mapLocation, {icon: customMarker, draggable: _this.draggableValue})
      _this._marker.addTo(this._map)
      _this._marker.on("drag", function(e){
        _this.latitudeTarget.value = e.target.getLatLng().lat
        _this.longitudeTarget.value = e.target.getLatLng().lng
      })

    }
    return this._marker
  }

  autocomplete() {
    if (this._autocomplete == undefined) {
      this._autocomplete = new google.maps.places.Autocomplete(
        this.fieldTarget
      );
      //this._autocomplete.bindTo("bounds", this.map());
      this._autocomplete.setFields([
        "address_components",
        "geometry",
        "icon",
        "name",
      ]);
      this._autocomplete.addListener(
        "place_changed",
        this.locationChanged.bind(this)
      );
    }
    return this._autocomplete;
  }

  locationChanged() {
    let place = this.autocomplete().getPlace();
    this._map.setView([place.geometry.location.lat(), place.geometry.location.lng()], 6)
    let newLatLng = L.latLng(place.geometry.location.lat(), place.geometry.location.lng())
    this.marker().setLatLng(newLatLng).update()
    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()
  }
  preventSubmit(e) {
    if (e.key == "Enter") {
      e.preventDefault();
    }
  }


}

