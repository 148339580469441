import Carousel from 'stimulus-carousel'

export default class extends Carousel {
  static targets = ["btnNext", "btnPrev"]
  static values = {slidesPerView: {type: Number, default: 1}, breakpoints: Object, loop: {type: Boolean, default: false}}
  connect() {
    super.connect()
    this.breakpointsValue


    // The swiper instance.
    this.swiper

    // Default options for every carousels.
    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions() {
    return {
      slidesPerView: this.slidesPerViewValue,
      spaceBetween: 32,
      loop: this.loopValue,
      /*autoplay: {
        delay: 2500,
        disableOnInteraction: true,
      },*/
      navigation: {
        nextEl: this.btnNextTarget,
        prevEl: this.btnPrevTarget,
      },
      breakpoints: this.breakpointsValue
    }
  }
}