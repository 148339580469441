import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radiobutton", "packageWrap"]
  connect() {
    this.toggleStyles()
  }
  toggleStyles() {
    this.radiobuttonTargets.forEach(radiobutton => {
      const packageWrap = radiobutton.closest('div[data-checked-package-target="packageWrap"]')
      //package.classList.add('hidden')
      console.log(packageWrap)
      if (radiobutton.checked) {
        packageWrap.classList.add("border-primary")
      }
      else {
        packageWrap.classList.remove("border-primary")
      }
    })
  }
}