import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  scrollTo(event) {
    event.preventDefault();

    const targetId = event.currentTarget.getAttribute("href").replace("#", "");
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 150, 
        behavior: "smooth",
      });
    }
  }
}