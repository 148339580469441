import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["popupEl"]

    connect() {

    }

    show() {
        this.popupElTarget.classList.remove("hidden");
        this.popupElTarget.classList.add("block");
    }

    hide() {
        this.popupElTarget.classList.remove("block");
        this.popupElTarget.classList.add("hidden");
    }


}