import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {
  static targets = ["startDate", "endDate"]
  connect() {
    const _this = this

    function getShowMonths() {
      return window.innerWidth < 640 ? 1 : 2;
    }

    this.config = {
      dateFormat: "m/d/Y",
      locale: {
        rangeSeparator: ' - '
      },
      showMonths: getShowMonths(),
      mode: "range",
      position: "auto right",
      defaultDate: [
        _this.startDateTarget.value || null,
        _this.endDateTarget.value || null
      ],
      onChange: function (selectedDates, dateStr, instance) {
        if (selectedDates.length === 2) {
          _this.startDateTarget.value = instance.formatDate(selectedDates[0], "m/d/Y");
          _this.endDateTarget.value = instance.formatDate(selectedDates[1], "m/d/Y");
        } else {
          _this.startDateTarget.value = '';
          _this.endDateTarget.value = '';
        }
      }
    }

    window.addEventListener('resize', function () {
      _this.config.showMonths = getShowMonths();
      _this.flatpickr.set('showMonths', _this.config.showMonths);
    });
    super.connect();
  }
}