import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {
  static targets = ["selectedDate",]
  connect() {
    const _this = this

    this.config = {
      dateFormat: "m/d/Y",
      position: "auto left",
      onChange: function(selectedDates, dateStr, instance) {
        _this.selectedDateTarget.value = dateStr;
      }
    }

    try {
      super.connect();
    } catch (error) {
      console.error("Error connecting Flatpickr controller:", error);
    }
  }
}