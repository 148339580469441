import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect(){
    this.element.addEventListener('keydown', function(event) {
      if(event.keyCode == 13) {
        console.log("enter")
      }
   });
   console.log("connected form prevent")
  }

}