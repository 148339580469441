import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"

export default class extends Controller {
  static targets = ["btnNext", "btnPrev"]

  connect() {
    this.initCarousel()
    window.addEventListener('resize', () => this.handleResize())
    this.handleResize()
  }

  disconnect() {
    window.removeEventListener('resize', () => this.handleResize())
    if (this.swiper) {
      this.swiper.destroy()
    }
  }

  initCarousel() {
    const options = {
      slidesPerView: 2,
      spaceBetween: 32,
      loop: false,
      centeredSlides: true,
      navigation: {
        nextEl: this.btnNextTarget,
        prevEl: this.btnPrevTarget,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 16,
          centeredSlides: false,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 16,
          centeredSlides: false,
        }
      },
    }

    // Initialize swiper only if screen width is less than 768 pixels
    if (window.innerWidth < 1024) {
      this.swiper = new Swiper(this.element, options)
    }
  }

  handleResize() {
    if (window.innerWidth < 1024) {
      if (!this.swiper) {
        this.initCarousel()
      }
    } else {
      if (this.swiper) {
        this.swiper.destroy()
        this.swiper = null
      }
    }
  }
}
