import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["button", "removeGame", "message"]

  static values = { isRemovable: { type: Boolean, default: false } }

  connect() {
    this.setIsRemovableValue()

    if (this.isRemovableValue) {
      this.removeWithCount()
    }
  }

  removeWithCount() {
    const counter = this.element.parentElement.querySelectorAll('div[data-controller="remove-score"]').length;
    if (counter == 1) {
      const message = document.getElementById('scoreAlert');
      message.classList.remove("hidden")
      setTimeout(() => {
        message.classList.add("hidden")
      }, 5000)
    } else {
      this.element.remove()
    }
  }

  setIsRemovableValue() {
    if (this.element.parentElement.parentElement.id == "game_fields_list_native") {
      this.isRemovableValue = true
    }
  }
}
