import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["trophyWrap", "buttonText"]
  static values = { count: { type: Number, default: 0 } }

  connect() {  
    this.setDefaultCountValue()
  }

  counterUp() {
    this.countValue += 1
    this.changeText()
    console.log(this.countValue)
  }

  counterDown() {
    if (this.countValue > 0) {
      this.countValue -= 1
    }
    this.changeText()
  }

  setDefaultCountValue() {
    const trophyItemElements = this.trophyWrapTarget.querySelectorAll('[data-trophy="item"]')
    this.countValue = trophyItemElements.length
    this.changeText()
  }

  changeText() {
    if (this.countValue == 0) {
      this.buttonTextTarget.innerHTML = "Add"
    }
    else {
      this.buttonTextTarget.innerHTML = "Add Another Trophy"
    }
  }

  remove(){
    this.element.remove();
  }
}