import { Controller } from "@hotwired/stimulus"
import { Accordion } from "flowbite"

export default class extends Controller {
  static targets = ["targetEl", "triggerEl"]

  connect() {
    console.log("accordion connected")
    const accordionItems =  this.accordionItems()
    const options = {
      activeClasses: 'bg-transparent',
      inactiveClasses: 'text-base-content',
    }

    this.accordion = new Accordion(accordionItems, options)

  }

  accordionItems(){
    const accordionItemsArray = this.triggerElTargets.map((element, index) => ({id:element.id, triggerEl:element, active: index = 0 ? true : false}))
    this.targetElTargets.map((element, index) => (
      accordionItemsArray[index].targetEl = element
    ))
    return accordionItemsArray

  }

}
