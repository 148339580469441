import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'counter']
  static values = { count: { type: Number, default: 0 }, availability: { type: Number, default: 0 }, availabilityLimit: { type: Boolean, default: false } }

  connect() {
    this.setValue()
  }

  counterUp() {
    if (this.availabilityLimitValue) {
      if (this.countValue < this.availabilityValue) {
        this.countValue += 1
        this.setValue()
      }
      else {
        return false
      }
    }
    else {
      this.countValue += 1
      this.setValue()
    }

  }

  counterDown() {
    if (this.countValue > 0) {
      this.countValue -= 1
    }
    this.setValue()
  }

  setValue() {
    this.counterTarget.innerHTML = this.countValue
    this.inputTarget.value = this.countValue
  }

}
