import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    connect() {
      this.setTopValue()
      console.log("connected")
    }
  
    setTopValue(){
      this.element.style.top = Math.floor(document.getElementById("header").offsetHeight)-1 + "px"
    }
  
  }