import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {

  static values = {
    selectConstraint: {type: String, default: "null"},
    createTags: {type: Boolean, default: false},
  }

  connect() {
    this.initTomSelect()
    console.log("connected")
  }

  disconnect(){
    if (this.select){
      this.select.destroy()
    }
  }

  initTomSelect(){
    const options= {
      plugins: ['remove_button'],
      maxItems: this.selectConstraintValue,
      create: this.createTagsValue,
      createOnBlur: true,
      // maxOptions: 0,
      onItemAdd:function(){
        this.setTextboxValue('');
        this.refreshOptions();
      },
    }
    this.select = new TomSelect(this.element, options)
    this.select.on('item_remove', function (value, data) {
      if (this.input.dataset.searchurl) {
        let items = this.items;
        let gameIds = items.map(function(el, idx) {
          return `game_ids[${idx}]=${el}`
        }).join('&');
        const url = `${this.input.dataset.searchurl}?update_filter=true&${gameIds}`
        fetch(url, {
          headers: {
            Accept: "text/vnd.turbo-stream.html"
          }
        })
        .then(r => r.text())
        .then(html => {
          Turbo.renderStreamMessage(html)
          window.dispatchEvent(new Event('itemRemoved'));
        })
      }
    })
    this.select.on('option_add', function (value, data) {
      if (this.input.dataset.customgameurl) {
        const gametype = this.input.dataset.gametype;
        const url = `${this.input.dataset.customgameurl}?game_name=${value}&game_type=${gametype}&gameidentifier=${this.input.dataset.gameidentifier}`;

        fetch(url, {
          headers: {
            Accept: "text/vnd.turbo-stream.html"
          }
        })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
      }
      if (this.input.dataset.relationurl) {
        const selected_items = this.items.map(function(el, idx) {
          return 'selected_item[' + idx + ']=' + el;
        }).join('&');
        const url = `${this.input.dataset.relationurl}?relation_name=${value}&${selected_items}`;
        fetch(url, {
          headers: {
            Accept: "text/vnd.turbo-stream.html"
          }
        })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
      }

    })
    this.select.on('item_add', function (value, data) {
      if (this.input.dataset.searchurl) {
        let items = this.items;
        let gameIds = items.map(function(el, idx) {
          return `game_ids[${idx}]=${el}`
        }).join('&');
        const url = `${this.input.dataset.searchurl}?update_filter=true&${gameIds}`
        fetch(url, {
          headers: {
            Accept: "text/vnd.turbo-stream.html"
          }
        })
        .then(r => r.text())
        .then(html => {
          Turbo.renderStreamMessage(html)
          window.dispatchEvent(new Event('itemAdded'));
        })
      }
    })


    if (this.select.settings.maxItems == 1) {
      const _this = this
      _this.select.on('focus', function (value, data) {
        _this.select.clear()
      })
    }

    if (this.select) {
      const _this = this;
      _this.select.on("type", function (value, data) {
        _this.select.settings.maxOptions = 1000;
      })
    }
  }
}
