import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  connect() {
    this.setConfirmMethod();
  }

  setConfirmMethod() {
    if (window.innerWidth > 768) {
      Turbo.setConfirmMethod(() => {
        this.element.showModal();
        return new Promise((resolve) => {
          this.element.addEventListener(
            "close",
            () => {
              resolve(this.element.returnValue === "confirm");
            },
            { once: true }
          );
        });
      });
    }
  }

  disconnect() {
    this.element.remove();
  }
}
