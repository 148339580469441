import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("autosave connected")
  }

  submitForm() {
    this.element.requestSubmit()
  }
}