import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message", "form"]
  connect() {
    console.log("autosave connected")
  }

  removeElement() {
    this.messageTarget.remove()
    this.formTarget.classList.remove("hidden")
  }
}