import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.buttonClicked = false;
    }

    Disable(event) {
        if (!this.buttonClicked) {
            this.buttonClicked = true;
          } else {
            event.preventDefault();
          }
    }

}