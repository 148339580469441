import Carousel from 'stimulus-carousel'

export default class extends Carousel {
  static targets = ["btnNext", "btnPrev"]
  connect() {
    super.connect()
    this.activeClass()
    

    // The swiper instance.
    this.swiper

    // Default options for every carousels.
    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions() {
    return {
      slidesPerView: "auto",
      slidesPerGroup: 2,
      allowTouchMove: true,
      navigation: {
        nextEl: this.btnNextTarget,
        prevEl: this.btnPrevTarget,
      },
      breakpoints: {
        768: {
          allowTouchMove: false,
        },        
      },
    }
  }

  activeClass(){
    const elements = document.querySelectorAll('.swiper-slide');
    let previousElement = null;

    function handleClick(element) {

      if (previousElement !== null) {
        previousElement.classList.remove('active');
      }

      element.classList.add('active');

      previousElement = element;
    }

    elements.forEach(element => {
      element.addEventListener('click', () => {
        handleClick(element);
      });
    });
  }
}