import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets=["preloader", "slider"]

  connect() {
    this.hidePreloader()    
  }

  hidePreloader() {
    setTimeout(() => {
      this.preloaderTarget.remove()
      this.sliderTarget.classList.remove("opacity-0")
    }, 300);
  }
}