import { Controller } from "@hotwired/stimulus"
import Datepicker from 'flowbite-datepicker/Datepicker'


export default class extends Controller {
  connect() {
    let blockedDates = []
    let minDate = null;
    let maxDate = null;
    if (this.element.dataset.blockedDates && this.element.dataset.blockedDates.length) {
      blockedDates = this.element.dataset.blockedDates.split('&')
    }
    if (this.element.dataset.minDate && this.element.dataset.minDate.length) {
      minDate = this.element.dataset.minDate
    }
    if (this.element.dataset.maxDate && this.element.dataset.maxDate.length) {
      maxDate = this.element.dataset.maxDate
    }
    new Datepicker(this.element, {
      datesDisabled: blockedDates,
      minDate: minDate,
      maxDate: maxDate
    });
  }
}
