import { Controller } from "@hotwired/stimulus"
import {DateRangePicker} from "flowbite-datepicker";


export default class extends Controller {
    connect() {
        new DateRangePicker(this.element, {
        });
    }
}
